/* .tariffs-div p {
    font-size: 125%;
    font-weight: 500;
    margin-bottom: 30px;
}

.tariffs-containers {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    text-transform: uppercase;
}

.tariffs-container {
    height: 600px;
    width: 500px;
    border-radius: 15px;
    border: 1px solid #8A7DE7;
    box-shadow: 0px 2px 10px 0px rgba(12, 11, 11, 0.05);
    text-align: center;
    margin-bottom: 10%;
}

.tariffs-container-header {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    border-radius: 15px 15px 0 0;
    height: 20%;
    color: white;
    background: #8A7DE7;
}

.tariffs-container-header h2 {
    font-weight: 600;
    font-size: 120%;
}

.tariffs-container-header h3 {
    font-weight: normal;
}

.tariffs-container-body {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    background: white;
    height: 80%;
    padding: 5%;
    border-radius: 0 0 15px 15px;
}

.tariffs-container-body p {
    color: #8A7DE7;
    font-weight: 700;
}

.tariffs-container-body ul {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.tariffs-container-body li:first-of-type {
    border-top: 1px solid #d1cede;
}

.tariffs-container-body li {
    padding: 4% 0;
    list-style-type: none;
    text-align: left;
    font-size: 90%;
    border-bottom: 1px solid #d1cede;
    display: flex;
    align-items: center;
}

.tariffs-container-body button {
    min-height: 50px;
    min-width: 200px;
    max-width: 250px;
    border: none;
    border-radius: 23px;
    background: #8A7DE7;
    color: white;
    box-shadow: 0px 2px 10px rgba(12, 11, 11, 0.05);
    border-radius: 10px;
    font-family: "Montserrat";
    padding: 0 2%;
    margin-top: 10px;
}

.active-line {
    color: #8A7DE7;
    font-weight: 600;
}

.unactive-line {
    color: gray;
    text-decoration: line-through;
    opacity: 0.7;
}


@media (max-width: 950px) {
    .tariffs-div {
        width: 100%;
    }

    .tariffs-containers {
        justify-content: center;
    }

} */

.tariffs-container {
	box-sizing: border-box;
	padding: 50px 50px 0 50px;
}

.tariffs__title {
	margin-bottom: 50px;

	.sales-description {
		color: #1fbd54;
		font-weight: 600;
		font-size: 18px;
		margin-top: 10px;

		@media (max-width: 1060px) {
			font-size: 16px;
		}
		@media (max-width: 460px) {
			font-size: 13px;
		}
	}

	h2 {
		color: #222;
		font-size: 30px;
		font-weight: 600;
		line-height: 40px;
		text-align: center;
	}

	p {
		color: #6a6a6b;
		text-align: center;
		font-size: 14px;
		font-weight: 400;
		line-height: 24px;
		margin-top: 15px;
	}
}

.tariffs__periods {
	max-width: 600px;
	width: 100%;
	margin: 0 auto;
	display: flex;
}

.tariffs-period {
	width: 25%;
	text-align: center;
	display: flex;
	flex-direction: column;
	align-items: center;
	position: relative;

	&::before {
		content: '';
		width: 100%;
		height: 1px;
		background: #e8e8e8;
		position: absolute;
		left: 0;
		top: 8px;
	}

	&:first-child::before {
		width: 50%;
		right: 0;
		left: auto;
	}

	&:last-child::before {
		width: 50%;
	}

	.circle {
		width: 18px;
		height: 18px;
		border-radius: 100%;
		display: block;
		background: #e8e8e8;
		margin-bottom: 5px;
		cursor: pointer;
		position: relative;
		z-index: 2;
	}

	p {
		color: #222;
		font-size: 14px;
		font-weight: 500;
		line-height: 20px;
	}

	.discount {
		margin-top: 2px;
		color: #8a7de7;
		font-size: 12px;
		font-weight: 700;
		line-height: 14px;
		display: block;
	}

	&.active .circle {
		background: #8a7de7;
	}
}

.tariffs__list {
	margin-top: 50px;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	gap: 30px;
}

.tariff {
	width: calc(25% - 23px);
	border-radius: 15px;
	background: #fff;
	box-shadow: 0px 0px 10px 0px rgba(12, 11, 11, 0.03);
	box-sizing: border-box;
	padding: 30px;
	position: relative;

	.input-note {
		right: -20px;
		top: 10px;
	}
}

.tariff__header {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
}

.tariff-sale {
	position: absolute;
	left: 50%;
	top: 0;
	transform: translate(-50%, -50%);
	font-size: 16px;
	background: #1fbd54;
	color: #fff;
	padding: 6px 12px;
	line-height: 18px;
	border-radius: 5px;

	@media (max-width: 1060px) {
		padding: 5px 10px;
		font-size: 13px;
		font-weight: 500;
	}
}

.tariff__name {
	text-align: center;
	color: #222222;
	font-weight: 600;
}

.full-price {
	display: flex;
	align-items: center;
	gap: 5px;
	font-size: 14px;
	margin-top: 5px;
	color: #22222266;
}

.price .tariff__name {
	color: #222;
	text-align: center;
	font-feature-settings: 'clig' off, 'liga' off;
	font-size: 19px;
	font-weight: 600;
	line-height: 26px;
	text-align: center;
}

.tariff__price {
	text-align: center;
	margin-top: 20px;
	padding-bottom: 5px;
	border-bottom: 1px solid #ebf1f8;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 5px;
	flex-wrap: wrap;

	p {
		width: 100%;
		text-align: left;
		margin-top: 20px;
		color: #222;
		font-size: 14px;
		font-weight: 400;
		line-height: 22px;
	}

	.prev_price {
		text-decoration: line-through;
		font-size: 12px;
		color: #22222266;
	}
	.price {
		color: #222;
		font-size: 26px;
		font-weight: 500;
		line-height: 30px;
		text-transform: uppercase;
		display: flex;
		align-items: center;
		gap: 7px;

		strong {
			font-weight: 700;
		}
	}

	.period {
		color: #777;
		font-size: 13px;
		font-weight: 500;
		line-height: 26px;
	}
}

.tariff__options {
	margin-top: 20px;
	margin-bottom: 115px;

	.input-note-desc {
		transform: translate(-50px, -50%);
	}

	ul {
		list-style: none;
		padding: 0;
		margin: 0;

		li {
			position: relative;
			box-sizing: border-box;
			display: flex;
			align-items: flex-start;
			gap: 5px;
			margin-bottom: 15px;
			color: #777;
			font-size: 14px;
			font-weight: 500;
			line-height: 20px;

			span,
			svg {
				opacity: 0.4;
			}

			&:last-child {
				margin-bottom: 0;
			}

			&::before {
				content: '';
				display: block;
				background-image: url(../../../../src/assets/cross.svg);
				width: 18px;
				height: 18px;
				background-repeat: no-repeat;
				background-size: contain;
			}

			span {
				text-decoration: line-through;
				flex: 1;
			}

			&.active {
				color: #222;
				opacity: 1;

				&::before {
					background-image: url('../../../../src/assets/check.svg');
				}

				span {
					text-decoration: none;
				}
			}
		}
	}
}

.tariff__footer {
	position: absolute;
	left: 0;
	bottom: 0;
	box-sizing: border-box;
	padding: 30px;
	width: 100%;
}

.tariff__btn {
	display: flex;
	justify-content: center;
	align-items: center;

	button {
		box-shadow: 0px 2px 10px 0px rgba(12, 11, 11, 0.05);
		border-radius: 10px;
		background: #8a7de7;
		border: none;
		cursor: pointer;
		width: 100%;
		height: 50px;
		display: flex;
		justify-content: center;
		align-items: center;
		outline: none;
		color: #fff;
		font-family: 'Montserrat';
		font-size: 16px;
		font-weight: 500;
		line-height: 20px;
		transition: all 0.3s;

		&:hover {
			background: #4e41ab;
		}
	}
}

.tariff__more {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 15px;

	a {
		display: flex;
		align-items: center;
		gap: 5px;
		color: #222;
		font-feature-settings: 'clig' off, 'liga' off;
		font-size: 13px;
		font-weight: 400;
		line-height: 17px;
		transition: all 0.3s;
		padding-bottom: 3px;
		border-bottom: 1px dashed #222;
		text-decoration: none !important;

		i {
			display: flex;
			align-items: center;
			justify-content: center;
		}

		path {
			transition: all 0.3s;
		}

		&:hover {
			color: #4e41ab;
			border-color: #4e41ab;

			path {
				stroke: #4e41ab;
			}
		}
	}
}

.tariffs {
	max-width: 1920px;
}

.tariffs-other {
	margin-top: 80px;
}

@media (max-width: 1600px) {
	.tariff {
		width: calc(33.333% - 20px);
	}
}

@media (max-width: 1060px) {
	.tariffs-container {
		padding: 30px 50px 0 50px;
	}

	.tariffs__title {
		h2 {
			font-size: 30px;
			line-height: 30px;
		}

		margin-bottom: 40px;
	}

	.tariffs__list {
		gap: 20px;
	}

	.tariff {
		width: calc(50% - 10px);
	}
}

@media (max-width: 768px) {
	.tariffs-header {
		min-height: 100px;
	}

	.tariff__header {
		// padding: 30px 20px 0 20px;
	}
}

@media (max-width: 480px) {
	.tariff {
		width: 100%;
	}

	.tariffs__title {
		margin-bottom: 30px;

		h2 {
			font-size: 19px;
			line-height: 23px;
		}
	}

	.tariffs-period {
		p {
			font-size: 12px;
			line-height: 18px;
		}

		.discount {
			font-size: 11px;
			list-style: 13px;
		}
	}

	.tariffs__list {
		margin-top: 35px;
	}

	.tariff__price {
		.price {
			font-size: 24px;
			line-height: 28px;
		}

		.period {
			font-size: 12px;
			line-height: 25px;
		}
	}

	.tariffs__title p {
		font-size: 12px;
		line-height: 20px;
		margin-top: 10px;

		br {
			display: none;
		}
	}

	.tariffs-other {
		margin-top: 50px;
	}
}
