.data-source__add {
	p {
		color: #333;
		font-family: Montserrat;
		font-size: 16px;
		font-style: normal;
		font-weight: 400;
		line-height: 24px;
		margin-bottom: 10px;
	}

	.btn {
		max-width: 210px;
		width: 100%;
		height: 58px;
		gap: 5px;

		@media (max-width: 480px) {
			max-width: 100%;
		}
	}
}

.data-source-item {
	margin-bottom: 20px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-radius: 10px;
	border: 1px solid #f6f6f6;
	background: #fff;
	box-shadow: 0px 2px 10px 0px rgba(12, 11, 11, 0.03);
	box-sizing: border-box;
	padding: 0 20px;
	min-height: 58px;

	&:last-child {
		margin-bottom: 0;
	}

	&.error {
		background: #ee5656;
		margin-bottom: 0;
	}
}

.source-item-error {
	svg {
		height: 20px;
		width: 20px;
		cursor: pointer;
	}
}

.data-source-item__url {
	color: #333;
	font-size: 15px;
	font-weight: 400;
	line-height: 18px;
	word-break: break-all;
}

.data-source-item {
	&.error .data-source-item__url {
		color: #fff;
	}

	&.freezing {
		box-shadow: 0px 2px 10px 0px rgba(12, 11, 11, 0.01);
		border: none;

		.data-source-item__url {
			color: #828283;
		}
	}
}

.data-source-item__action {
	position: relative;
	display: flex;
	align-items: center;
	gap: 15px;

	.action-menu {
		z-index: 1;
	}

	button {
		background: none;
		border: none;
		cursor: pointer;
		outline: none;

		path {
			transition: all 0.3s;
		}

		&:hover path {
			stroke: #4e41ab;
		}
	}
}

.data-source-item.error .data-source-item__action button {
	path {
		stroke: #fff;
	}

	&:hover path {
		stroke: #000;
	}
}

.data-source__list {
	@media (max-width: 480px) {
		margin-top: 20px;
	}
}

.data-source__list .error-message {
	display: flex;
	justify-content: flex-end;
	margin-top: 10px;
	color: #ee5656;
	font-size: 14px;
	font-weight: 400;
	line-height: 20px;
	margin-bottom: 20px;
}

.download-method__title {
	margin-bottom: 35px;

	h3 {
		font-size: 20px;
		font-weight: 400;
		line-height: 26px;
		color: #333;
	}

	ol,
	ul {
		padding: 0;
		margin: 0;
		margin-top: 15px;
		box-sizing: border-box;
		padding-left: 17px;
	}

	li {
		margin-bottom: 10px;
		color: #333;
		font-size: 15px;
		font-weight: 400;
		line-height: 23px;

		a {
			color: #4e41ab;
			text-decoration-line: underline;
		}
	}
}

.download-method__tabs {
	display: flex;
	gap: 20px;
	margin-bottom: 30px;
	flex-wrap: wrap;
	justify-content: space-between;

	.btn {
		border: none;
		background: #fff;
		height: 60px;
		max-width: 220px;
		color: #8a7de7;
		transition: all 0.3s;
		box-shadow: 0px 2px 10px 0px rgba(12, 11, 11, 0.03);

		&:hover,
		&.active {
			color: #fff;
			background: #8a7de7;
		}
	}
}

.download-method__block {
	display: none;

	&.open {
		display: block;
	}

	.download-method__block_flex {
		display: flex;
		justify-content: space-between;
		gap: 10px;
		align-items: center;
		margin-bottom: 10px;

		@media (max-width: 480px) {
			.input-file,
			button {
				max-width: 100% !important;
			}
			flex-wrap: wrap;
		}
	}

	.download-method__block_token {
		display: flex;
		gap: 5px;
		justify-content: space-between;
		align-items: flex-start;
		form {
			margin-bottom: 10px;
		}
		p {
			&:nth-child(2) {
				word-wrap: anywhere;
			}
		}
	}

	.settings-select__control {
		max-width: 100% !important;
	}

	.setting-select {
		margin-bottom: 20px !important;
	}

	.inputs-row {
		@media (max-width: 480px) {
			gap: 20px;
		}
	}

	.btn {
		max-width: 250px;
		height: 58px;
	}
}

.source-list {
	margin-top: 35px;
}

.source-item {
	box-sizing: border-box;
	padding: 20px 10px 20px 20px;
	margin-bottom: 20px;
	border-radius: 10px;
	border: 1px solid #f6f6f6;
	background: #fff;
	box-shadow: 0px 2px 10px 0px rgba(12, 11, 11, 0.03);
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.source-item__url {
	color: #333;
	font-family: Montserrat;
	font-size: 15px;
	font-weight: 400;
	line-height: 18px;
	text-decoration-line: underline;
	word-break: break-all;
}

.source-item__status {
	color: #4e41ab;
	font-size: 15px;
	font-weight: 400;
	line-height: 19px;
	margin-top: 10px;
}

.source-item__action button {
	background: none;
	cursor: pointer;
	border: none;
	outline: none;
	background: none;
	display: flex;
	justify-content: center;
	align-items: center;

	path {
		transition: all 0.3s;
	}

	&:hover path {
		fill: #4e41ab;
	}
}

.download-method__block p {
	margin-bottom: 10px;
	color: #333;
	font-size: 15px;
	font-weight: 400;
	line-height: 23px;

	a {
		color: #4e41ab;
	}
}

.statistics-diagram {
	max-width: 1183px;
	width: 100%;
}

.statistics-diagram__title {
	margin-bottom: 35px;

	h2 {
		color: #333;
		font-size: 20px;
		font-weight: 500;
		line-height: 28px;
		margin-bottom: 60px;
	}
}

.statistics-diagram-block {
	border-radius: 10px;
	border: 1px solid #f6f6f6;
	background: #fff;
	box-shadow: 0px 2px 10px 0px rgba(12, 11, 11, 0.03);
	box-sizing: border-box;
	padding: 30px;
	margin-bottom: 20px;
}

.statistics-diagram-block__source {
	display: flex;
	align-items: center;
	gap: 5px;
	color: #222;
	font-size: 16px;
	font-weight: 400;
	line-height: 24px;
	margin-bottom: 30px;

	a {
		color: #4e41ab;
		text-decoration: none;
	}
}

.statistics-diagram-block__date {
	display: flex;
	justify-content: space-between;
	align-items: center;
	color: #222;
	font-size: 16px;
	font-weight: 400;
	line-height: 24px;
	box-sizing: border-box;
	padding-left: 55px;
	margin-top: 20px;
}

.recharts-responsive-container {
	border-radius: 1px !important;
	border-bottom: 1px solid #ece6ff !important;
}

text.recharts-text.recharts-cartesian-axis-tick-value {
	fill: #333 !important;
}

.contact-views-diagram {
	.recharts-responsive-container {
		border-left: 1px solid #ece6ff !important;
	}

	.statistics-diagram-block__date {
		padding-left: 0;
	}
}

.sources-statistics {
	padding: 50px 50px 0px 50px;
}

.sources-statistics-support .banner-support__block {
	max-width: 1183px;
}

@media (max-width: 768px) {
	.sources-statistics {
		padding: 30px 20px 60px 20px;
	}
}

@media (max-width: 650px) {
	.statistics-diagram .input-date {
		width: auto;
	}
}

@media (max-width: 480px) {
	.source-item {
		flex-direction: column;
		align-items: flex-start;
		gap: 5px;
	}

	.data-source-item__action {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		width: 100%;
		flex: 1;
	}

	.download-method__tabs {
		flex-direction: column;

		.btn {
			max-width: 100%;
		}
	}

	.statistics-diagram__title h2 {
		margin-bottom: 25px;
	}

	.statistics-diagram-block {
		padding: 10px;
	}

	.recharts-responsive-container {
		overflow: hidden;
		overflow-x: auto;
		height: 430px !important;
	}

	.recharts-surface,
	.recharts-wrapper {
		height: 430px !important;
		width: auto;
	}

	/* .recharts-responsive-container {
          height: 375px !important;
      }
  
      .recharts-surface {
          height: 375px !important;
      }
  
      .recharts-wrapper {
          height: 375px !important;
      } */

	.statistics-diagram-block__date {
		font-size: 10px;
		font-weight: 400;
	}

	.sources-statistics {
		padding-bottom: 0;
	}
}

@media (max-width: 375px) {
	.statistics-diagram-block__source {
		font-size: 10px;
		line-height: 16px;
	}
}
