.loader-wrapper {
	padding: 10px 0;
	text-align: center;

	&.fixed {
		top: 0;
		left: 0;
		position: fixed;
		z-index: 999;
		height: 100%;
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	&.absolute {
		position: absolute;
		top: 0;
		left: 50%;
		transform: translate(-50%, 0);
		padding: 0;

		&.isMini {
			transform: translate(-50%, 0) scale(0.5);
		}
	}

	.loader {
		display: block;
		color: #8a7de7;
		font-size: 45px;
		text-indent: -9999em;
		overflow: hidden;
		width: 46px;
		height: 46px;
		border-radius: 50%;
		position: relative;
		transform: translateZ(0);
		animation: mltShdSpin 1.7s infinite ease, round 1.7s infinite ease;
	}

	@keyframes mltShdSpin {
		0% {
			box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em,
				0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
		}

		5%,
		95% {
			box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em,
				0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
		}

		10%,
		59% {
			box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em,
				-0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em,
				-0.297em -0.775em 0 -0.477em;
		}

		20% {
			box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em,
				-0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em,
				-0.749em -0.34em 0 -0.477em;
		}

		38% {
			box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em,
				-0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em,
				-0.82em -0.09em 0 -0.477em;
		}

		100% {
			box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em,
				0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
		}
	}

	@keyframes round {
		0% {
			transform: rotate(0deg);
		}

		100% {
			transform: rotate(360deg);
		}
	}
}

.hidden {
	.blur {
		background: inherit !important;
	}
}
.blur-wrapper {
	.blur-all {
		filter: blur(4px);
	}

	.blur.blur-info {
		.btn-register {
			padding: 20px 0;
		}
	}

	&:hover {
		transition: all 0.3s ease 0s;
		.btn-register {
			opacity: 1;
			background: #4e41ab;
		}
	}
}
.blur {
	top: 0;
	left: 0;
	position: fixed;
	z-index: 42;
	height: 100%;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 0px 10px 10px 0px;
	background: rgba(255, 255, 255, 0.3);
	filter: blur(4px);
	transition: all 0.3s;

	&-block {
		position: relative;
		z-index: 99;
		height: 100%;
		width: 100%;
		display: flex;
		align-items: center;
		border-radius: 0px 10px 10px 0px;
		background: rgba(255, 255, 255, 0.3);
		filter: blur(4px);
		transition: all 0.3s;
	}

	&.blur-info {
		border-radius: 0px 10px 10px 0px;
		background: rgba(255, 255, 255, 0.3);
		filter: blur(4px);
		transition: all 0.3s;
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;

		.btn-register {
			color: #fff;
			width: 100%;
			border-radius: 6px;
			padding: 5px 7px;
			// height: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
			text-align: center;
			text-decoration: none;
			transition: all 0.3s ease 0s;
		}

		&:hover {
			filter: blur(0px);

			.btn-register {
				opacity: 1;
			}
		}
	}
}
