.wrapper {
	display: flex;
	align-items: center;
	justify-content: center;
	max-width: 500px;
	padding: 0 10px;
	margin: auto;
	width: 100%;
}

.block {
	display: flex;
	flex-direction: column;
	gap: 10px;
	width: 100%;
	align-items: center;

	button {
		border: none;
		outline: none;
		border-radius: 10px;
		width: 100%;
		transition: all 0.3s ease 0s;
		margin-top: 30px;
	}
}

.main {
	font-size: 150px;
	color: #fff;
	font-weight: 600;
	border-radius: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 50px;

	&.completed {
		background-color: #20bf55;
	}
	&.error {
		background-color: #f00;
	}
	&.pending {
		background-color: #ffa500;
	}

	@media (max-width: 768px) {
		font-size: 100px;
		padding: 30px;
	}

	@media (max-width: 567px) {
		font-size: 70px;
		padding: 20px;
	}
}

.second {
	font-size: 20px;
}

.info {
	font-size: 16px;
	text-align: center;

	span {
		color: #8a7de7;
	}
}
