.wrapper {
	display: flex;
	gap: 10px;
	align-items: center;

	:global {
		.input-block {
			margin-bottom: 0;
		}
	}

	> div {
		flex: 1;
	}

	svg {
		width: 28px;
		height: 28px;
		// height: 100%;
		cursor: pointer;
		fill: #777777;
	}
}

.label {
	display: flex;
	align-items: center;
	gap: 10px;
}

.text {
	text-decoration: underline;
}
