.ListWarehouseCitiesWrapper {
	margin-top: 45px;
	@media (max-width: 768px) {
		margin-top: 30px;
	}
	.title {
		font-size: 26px;
		color: #222222;
		margin-bottom: 25px;
		font-weight: 500;
		position: relative;
		&::after {
			content: '';
			position: absolute;
			left: 0;
			bottom: -5px;
			width: 100%;
			height: 1px;
			background-color: #ebf1f8;
		}

		@media (max-width: 768px) {
			font-size: 17px;
		}
	}

	.list {
		display: flex;
		align-items: center;
		gap: 13px;
		flex-wrap: wrap;
	}

	.item {
		background: #1e2321;
		color: #fff;
		padding: 8px 13px;
		border-radius: 6px;
		@media (max-width: 768px) {
			padding: 6px 11px;
			font-size: 12px;
		}
	}
}
