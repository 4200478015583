.popup-content {
	padding: 30px 46px !important;
	box-sizing: border-box;
	max-width: 500px;
	width: 100%;
	border-radius: 10px;
	border: 1px solid #f6f6f6;
	background: #fff;
	box-shadow: 0px 2px 10px 0px rgba(12, 11, 11, 0.03);

	/* overflow: hidden; */

	&.employees-popup-base-content {
		max-width: 435px;
		width: 100%;
	}

	&.delete-popup-base-content {
		max-width: 600px;
		width: 100%;
	}

	&.requisites-popup-base-content {
		max-width: 900px;
		min-width: 1px !important;
		padding: 50px !important;
	}
}

.requisites-popup-header,
.distributor-popup-header,
.delete-popup-header {
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.delete-popup-contact {
	font-size: 20px;
	margin-top: 20px;

	@media (max-width: 480px) {
		font-size: 14px;
	}
	a {
		text-decoration: none;
		color: #8a7de7;
	}
}
.requisites-popup-header {
	position: relative;
	margin-bottom: 35px;

	li {
		padding: 10px 0;
	}
}

.requisites-popup-title {
	position: relative;
	margin-bottom: 35px;
}

.requisites-popup-header {
	h1 {
		color: #222;
		font-size: 22px;
		font-weight: 500;
		line-height: 30px;
		display: flex;
		align-items: flex-start;
		gap: 5px;
	}

	.note {
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;
	}
}

.requisites-popup-base ul li {
	padding: 10px;
}

.requisites-popup-header {
	.note {
		path {
			transition: all 0.3s;
		}

		&:hover path {
			stroke: #8a7de7;
		}
	}

	.note-text {
		position: absolute;
		left: 0;
		top: 70%;
		color: #333;
		font-size: 11px;
		font-weight: 400;
		line-height: 13px;
		border-radius: 5px;
		border: 1px solid #f8f8fa;
		background: #f6f6f6;
		box-shadow: 0px 2px 10px 0px rgba(12, 11, 11, 0.03);
		z-index: 5;
		box-sizing: border-box;
		padding: 10px;
		opacity: 0;
		z-index: -111;
		transition: all 0.3s;
	}

	.note:hover ~ .note-text {
		opacity: 1;
		z-index: 55;
	}

	button {
		cursor: pointer;
		background: none;
		border: none;
		outline: none;
	}
}

.distributor-popup-header button,
.delete-popup-header button {
	cursor: pointer;
	background: none;
	border: none;
	outline: none;
}

.delete-popup-header .button-close {
	position: absolute;
	right: 15px;
	top: 15px;
}

.requisites-popup-header button path,
.distributor-popup-header button path,
.delete-popup-header button path {
	transition: all 0.3s;
}

.requisites-popup-header button:hover path,
.distributor-popup-header button:hover path,
.delete-popup-header button:hover path {
	stroke: #4e41ab;
}

.requisites-popup-header h3,
.distributor-popup-header h3,
.delete-popup-header h3 {
	color: #222;
	font-size: 22px;
	font-weight: 500;
	line-height: 30px;
}

.tariff-popup-base-content {
	width: 900px;
}

.tariff-popup-content-layers {
	margin-top: 15px;
	display: flex;
	justify-content: space-evenly;
	gap: 10px;
}

.layer,
.layer-header {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.layer {
	width: 300px;
	height: 200px;
	border: 1px solid #8a7de7;
	border-radius: 20px;
	justify-content: space-between;
	padding-bottom: 10px;
}

.layer-header {
	justify-content: space-evenly;
	width: 100%;
	height: 30%;
	background-color: #8a7de7;
	color: white;
	border-top-left-radius: 20px;
	border-top-right-radius: 20px;

	p {
		font-size: 60%;
	}
}

.layer {
	p:not(.layer-header p) {
		color: #8a7de7;
		font-size: 110%;
		font-weight: 600;
	}

	button {
		width: 80%;
		height: 15%;
		border: none;
		border-radius: 23px;
		background: #8a7de7;
		color: white;
		box-shadow: 0px 2px 10px rgba(12, 11, 11, 0.05);
		border-radius: 10px;
		font-family: 'Montserrat';
		padding: 0 2%;
		margin-top: 10px;
	}
}

.distributors-form {
	margin-top: 35px;

	.btn {
		max-width: 100%;
		margin-top: 35px;
	}
}

.delete-form {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 35px;
	gap: 30px;

	.btn {
		background: none;
		border: 1px solid #555;
		color: #333;
		font-family: 'Montserrat';
		font-size: 16px;
		font-weight: 500;
		line-height: 24px;
		max-width: 253px;

		&:hover {
			color: #fff;
			background: #8a7de7;
			border-color: #8a7de7;
		}
	}
}

.requisites-popup-list-item {
	padding: 10px 0;

	@media (max-width: 567px) {
		padding: 5px 0;
	}
}

.requisites-popup-content-stroke {
	display: flex;
	justify-content: space-between;
	gap: 15px;

	span {
		width: 1px;
		background: rgba(12, 11, 11, 0.05);
		height: 100%;
	}

	p {
		&:first-of-type {
			width: 50%;
			border-right: 1px solid rgba(12, 11, 11, 0.05);
			padding-bottom: 2%;
			flex: 0 0 50%;
		}

		&:nth-of-type(2) {
			width: 45%;
			flex: 0 0 45%;
			padding-bottom: 2%;
		}
	}
}

.producttoproject-popup-body {
	margin-top: 35px;

	> div {
		display: flex;
		gap: 10px;
	}

	.settings-select__control {
		width: 100% !important;
		border-radius: 10px !important;
		background: #ebf1f8 !important;
		height: 50px !important;
	}

	.settings-select__clear-indicator {
		position: absolute;
		right: 25px;
	}
}

.settings-select__helper-close {
	display: flex;
	align-items: center;
	width: 20px;
	vertical-align: middle;
	flex-shrink: 0;
	cursor: pointer;
	right: -15px;
}

.producttoproject-popup-body {
	.settings-select__placeholder {
		font-size: 14px !important;
		font-weight: 400;
		line-height: 20px !important;
	}

	.css-b62m3t-container {
		max-width: 300px !important;
		width: 100%;
	}

	.settings-select__option {
		cursor: pointer;
	}

	.settings-select__option--is-selected {
		background: #fff;
		color: #333 !important;
	}

	input {
		max-width: 300px;
		width: 100%;
		border-radius: 10px;
		background: #ebf1f8;
		font-family: 'Montserrat';
		height: 50px;
		box-sizing: border-box;
		padding-left: 15px;
		outline: none;
		border: none;
		color: #333;
		font-size: 14px;
		font-weight: 400;
		line-height: 20px;

		&::placeholder {
			color: #777;
			font-size: 14px;
			font-weight: 400;
			line-height: 20px;
		}
	}

	select option {
		font-family: 'Montserrat';
	}

	button {
		padding: 0 30px;
		box-sizing: border-box;
		cursor: pointer;
		min-height: 50px;
		border: 1px solid #8a7de7;
		border-radius: 10px;
		color: white;
		background: #8a7de7;
		box-shadow: 0px 2px 10px rgba(12, 11, 11, 0.05);
		font-family: 'Montserrat';
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 10px;
		transition: all 0.3s;

		&:hover {
			background: #4e41ab;
		}

		p {
			font-size: 110%;
		}
	}
}

.projectchange-popup-form {
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	gap: 10px;
	margin-bottom: 10px;

	input {
		max-width: 300px;
		width: 100%;
		border-radius: 10px;
		background: #ebf1f8;
		font-family: 'Montserrat';
		height: 50px;
		box-sizing: border-box;
		padding-left: 15px;
		outline: none;
		border: none;
		color: #333;
		font-size: 14px;
		font-weight: 400;
		line-height: 20px;

		&::placeholder {
			color: #777;
			font-size: 14px;
			font-weight: 400;
			line-height: 20px;
			font-family: 'Montserrat';
		}
	}
}

.projectchange-popup-button {
	max-width: 100%;
	width: 100%;
	cursor: pointer;
	height: 50px;
	border-radius: 8px;
	color: white;
	background: #8a7de7;
	box-shadow: 0px 2px 10px rgba(12, 11, 11, 0.05);
	font-family: 'Montserrat';
	font-size: 90%;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 14px;
	font-weight: 500;
	line-height: 20px;
	transition: all 0.3s;
	border: none;
	outline: none;

	&:hover {
		background: #4e41ab;
	}
}

.choosepartner-popup-header {
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 35px;

	h1 {
		color: #222;
		font-size: 24px;
		font-weight: 500;
	}

	button {
		background: none;
		border: none;
		outline: none;
		cursor: pointer;

		path {
			transition: all 0.3s;
		}

		&:hover path {
			stroke: #4e41ab;
		}
	}
}

.choosepartner-popup-body {
	width: 100%;
	overflow-y: auto;

	&::-webkit-scrollbar {
		height: 22px;
	}

	&::-webkit-scrollbar-track {
		background: #dacdff80;
		border-radius: 20px;
	}

	&::-webkit-scrollbar-thumb {
		background-color: #8a7de7;
		border-radius: 20px;
		border: 3px solid #dacdff;
	}

	scrollbar-width: thin;
	scrollbar-color: #8a7de7 #ebeaed;

	scrollbar-track-color: #ebeaed;
	scrollbar-thumb-color: #8a7de7;

	table {
		border-collapse: collapse;
		table-layout: fixed;
		width: 100%;

		tbody {
			border-spacing: 10px;
		}
	}

	.width-adapt {
		width: max-content;
		white-space: nowrap;
	}

	.width-adapt-min {
		width: fit-content;
		white-space: nowrap;
	}

	thead {
		height: 50px;
		border-radius: 10px;
		border: 1px solid #f6f6f6;
		background: #f8f8fa;
		box-shadow: 0px 2px 10px 0px rgba(12, 11, 11, 0.03);

		th:first-of-type {
			text-align: left;
			padding-left: 15px;
			width: min-content;
		}
	}

	tbody .first-row td:first-of-type {
		width: min-content;
	}

	thead th {
		&:first-of-type {
			width: 250px;
		}

		text-align: left;
		color: #333;
		font-size: 13px;
		font-style: normal;
		font-weight: 500;
		line-height: 20px;
	}
}

.first-row td p {
	font-size: 14px;
	font-weight: 500;
	line-height: 20px;
	text-align: left;
}

.choosepartner-popup-body {
	tbody::before {
		content: '@';
		display: block;
		line-height: 35px;
		text-indent: -99999px;
	}

	.manufacturer p:first-of-type {
		width: fit-content;
		padding: 4px 15px;
		border-radius: 10px;
		background: #20bf55;
		text-align: center;
		color: #fff;
		font-size: 14px;
		font-weight: 500;
		line-height: 20px;
		box-sizing: border-box;
	}

	.first-row td:not(.choosepartner-popup-body .first-row td:first-of-type) {
		text-align: center;
		padding: 0 0.325em;
	}

	.second-row {
		td {
			border-bottom: 1px solid rgba(218, 205, 255, 0.5);
			padding: 15px 0;

			p {
				color: #222;
				font-size: 14px;
				font-weight: 400;
				line-height: 22px;
			}

			a {
				font-weight: 500;
				text-decoration-line: underline;
				color: #222;
				transition: all 0.3s;

				&:hover {
					color: #4e41ab;
				}
			}
		}

		div {
			display: flex;
			justify-content: flex-end;
			gap: 15px;
		}

		button {
			display: flex;
			width: 187px;
			height: 40px;
			overflow: hidden;
			border-radius: 10px;
			border: none;
			box-shadow: 0px 2px 10px 0px rgba(12, 11, 11, 0.03);
			background: #fff;
			font-family: 'Montserrat';
			justify-content: center;
			align-items: center;
			color: #222;
			font-size: 14px;
			font-style: normal;
			font-weight: 500;
			line-height: 20px;

			&:last-of-type {
				color: #fff;
				background: #8a7de7;
			}
		}
	}
}

.choosepartner-popup-body-tableheader p {
	color: #333;
	font-family: 'Montserrat';
	font-size: 15px;
	font-style: normal;
	font-weight: 500;
	line-height: 20px;
}

/* .createproject-popup-base-content {
    width: 600px;
} */

.choose-partner-popup-base-content {
	max-width: 1160px;
	background: #f8f8fa;
	box-sizing: border-box;
	padding: 30px !important;
}

.company-info-mobile {
	display: none;
}

@media (max-width: 1250px) {
	.choose-partner-popup-base-content {
		width: 90%;
	}
}

@media (max-width: 1024px) {
	.choosepartner-popup-body table {
		width: 1200px;
	}
}

@media (max-width: 900px) {
	.tariff-popup-base-content,
	.distributor-popup-base-content,
	.producttoproject-popup-base-content,
	.createproject-popup-base-content {
		width: 80%;
	}
}

@media (max-width: 800px) {
	.tariff-popup-base-content,
	.distributor-popup-base-content,
	.requisites-popup-base-content,
	.producttoproject-popup-base-content,
	.createproject-popup-base-content,
	.modal-edit-project-content,
	.delete-popup-base-content {
		width: 90% !important;
	}

	.projectchange-popup-form {
		flex-direction: column;
		gap: 10px;
		margin-bottom: 10px;
	}

	.projectchange-popup-button {
		width: 100%;
		border-radius: 6px;
		height: 40px;
		font-size: 14px;
		line-height: 20px;
	}

	.projectchange-popup-form {
		margin-bottom: 15px;

		input {
			width: 100% !important;
			max-width: 100% !important;
		}
	}

	.closed-dropdown {
		width: 100% !important;
		max-width: 100% !important;
	}

	.producttoproject-popup-body {
		> div {
			margin-top: 25px;
			flex-direction: column;
			gap: 10px;
		}

		button {
			width: 100%;
			justify-content: center;
			gap: 10px;
		}
	}

	.layer {
		width: 100%;
	}
}

@media (max-width: 650px) {
	.tariff-popup-base-content,
	.requisites-popup-base-content {
		width: 100%;
		height: 100%;
	}

	.choose-partner-popup-base-content {
		width: 100%;
		height: 100%;
		overflow-y: auto;
	}

	.tariff-popup-content-layers {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: space-evenly;
	}

	.layer {
		width: 190px;
	}
}

@media (max-width: 650px) {
	.choosepartner-popup-body {
		padding: 0 2%;
		width: 100%;
		height: 100%;
		margin: 2% auto;
		overflow-y: auto;
		background: #f8f8fa;
	}

	.choosepartner-popup-header {
		padding: 0% 5%;

		h1 {
			font-size: 16px;
			text-align: left;
		}

		button {
			min-height: 30px;
			min-width: 30px;
		}
	}

	.choosepartner-popup-body {
		table {
			border: 0;
			width: 100%;
		}

		thead {
			border: none;
			clip: rect(0 0 0 0);
			height: 1px;
			margin: -1px;
			overflow: hidden;
			padding: 0;
			position: absolute;
			width: 1px;
		}

		table {
			tr {
				margin-bottom: 0.625em;
				background: none !important;
				box-shadow: none !important;
			}

			tbody tr td {
				border-top: none;
				border-bottom: none;
			}

			td {
				background: white;
				border-bottom: 1px solid #ddd;
				display: block;
				font-size: 12px;
				text-align: right;
			}

			.first-row td::before {
				content: attr(aria-label);
				float: left;
				font-weight: normal;
				background-color: white;
				height: max-content;
				width: 35%;
				text-align: center;
				font-size: 120%;
				font-weight: 500;
				padding-top: 10px;
				white-space: break-spaces;
			}

			td:last-child {
				border-bottom: 0;
			}
		}

		.second-row {
			border-bottom: 0;
		}

		table {
			tbody tr td:first-of-type {
				border-bottom-left-radius: 0px !important;
				border-left: none;
			}

			td {
				padding: 0;
			}
		}

		.manufacturer,
		.surcharge,
		.delivery,
		.license,
		.documents,
		.speak_languages {
			width: auto !important;
			padding-left: 0.7em !important;
			padding-right: 0 !important;
		}

		.manufacturer {
			border-top-left-radius: 10px;

			div {
				background: #f8f8fa;
				font-size: 12px !important;
				padding-left: 10px;
				padding-bottom: 20px;
				padding-top: 10px;
				text-align: left;
				margin-left: 35%;
				height: 100%;
			}
		}

		.surcharge p,
		.delivery p,
		.license p,
		.documents p,
		.speak_languages p {
			background: #f8f8fa;
			font-size: 12px !important;
			padding-left: 10px;
			padding-bottom: 20px;
			padding-top: 10px;
			text-align: left;
			margin-left: 35%;
			height: 100%;
		}

		.manufacturer p {
			font-size: 12px !important;
		}

		.second-row td:last-of-type {
			background: none;
		}
	}

	.company-info-mobile.active {
		display: block;
		white-space: break-spaces;
		margin-top: 5px;
	}

	.company-info-desktop {
		display: none !important;
	}

	.choosepartner-popup-body .second-row button {
		font-size: 12px;
		width: 48%;
	}

	.choosepartner-popup-header {
		background: #8a7de7;
		box-sizing: border-box;
		padding: 20px;
		margin: 0;

		h1 {
			color: #fff;
			font-size: 16px;
			font-weight: 500;
		}

		button path {
			stroke: #fff;
		}
	}

	.choose-partner-popup-base-content {
		padding: 0 !important;
		border: none;
	}

	.choosepartner-popup-body {
		tbody::before {
			display: none;
		}

		margin: 0;
		box-sizing: border-box;
		padding: 30px 20px;

		table .first-row td::before {
			color: #333;
			font-size: 9px;
			font-weight: 500;
			line-height: 10px;
			text-align: left;
			width: 20%;
		}

		.manufacturer p {
			font-size: 11px;
			font-weight: 400;
			line-height: 15px;

			&:first-of-type {
				font-size: 10px !important;
				margin-bottom: 15px;
			}
		}
	}

	.first-row .manufacturer > div {
		padding-top: 0;
		padding-bottom: 10px;
	}

	.choosepartner-popup-body {
		.manufacturer div,
		.surcharge p,
		.delivery p,
		.license p,
		.documents p,
		.speak_languages p {
			font-size: 11px !important;
			font-weight: 400;
			line-height: 15px;
			padding-bottom: 0;
			margin-left: 20%;
		}
	}

	.second-row td:first-child {
		display: none;
	}

	.choosepartner-popup-body .second-row button {
		border-radius: 5px;
	}

	.popup-content {
		padding: 20px 15px;
		border-radius: 7px !important;
	}

	.projectchange-popup-form input,
	.closed-dropdown {
		border-radius: 6px;
	}

	.requisites-popup-header {
		h1 {
			font-size: 16px;
			line-height: 20px;
		}

		margin-bottom: 20px;

		.note-text {
			font-size: 8px;
			font-weight: 400;
			line-height: 10px;
		}
	}

	.popup-content.requisites-popup-base-content {
		padding: 0;
		border: none;
		height: auto;
	}

	.requisites-popup-content {
		max-height: 400px;
		overflow-x: hidden;
		overflow-y: auto;
		box-sizing: border-box;
		padding: 20px;
		margin-top: 20px;
	}

	.requisites-popup-content-title {
		display: block;
		padding: 10px;
		font-size: 18px;
	}

	.requisites-popup-content {
		h2 {
			padding: 20px;
			margin-top: 20px;
		}

		&::-webkit-scrollbar {
			width: 4px;
		}
	}

	.partner-page-modal-requisites {
		.requisites-popup-header,
		ul li {
			box-sizing: border-box;
			padding: 20px;
			background: #8a7de7;
		}

		ul {
			@media (max-width: 567px) {
				margin-top: 20px;
			}
		}

		.requisites-popup-header {
			h1 {
				color: #fff;
			}

			path {
				stroke: #fff;
			}
		}
	}

	.requisites-popup-content-stroke p {
		font-size: 12px;
		line-height: 14px;
	}

	.projectchange-popup-form input {
		max-width: 100%;
	}

	.producttoproject-popup-body {
		.css-b62m3t-container,
		.settings-select__control {
			max-width: 100% !important;
		}
	}

	.requisites-popup-header h3,
	.distributor-popup-header h3,
	.delete-popup-header h3 {
		font-size: 16px;
		line-height: 20px;
	}

	.delete-form .btn {
		font-size: 13px;
		line-height: 16px;
	}
}

@media (max-width: 375px) {
	.requisites-popup-content-stroke p {
		font-size: 10px;
		line-height: 12px;
	}
}
