.profile-message {
	font-weight: 500;
	margin: 1% 0;
}

.tariff-header {
	width: 100%;
	height: 70px;
	padding: 0 20px;
	box-sizing: border-box;
	display: flex;
	justify-content: space-between;
	align-items: center;
	background: #ebeaed;
	box-shadow: 0px 2px 10px 0px rgba(12, 11, 11, 0.05);
	color: #6e6e6f;
	font-size: 20px;
	font-weight: 400;
	line-height: 24px;

	a {
		color: #8a7de7;
		text-align: right;
		font-size: 20px;
		font-weight: 400;
		line-height: 24px;
		text-decoration: none !important;
		transition: all 0.3s;
		border-bottom: 1px solid #8a7de7;

		&:hover {
			color: #4e41ab;
			border-color: #4e41ab;
		}
	}

	&.reject {
		background: #f00;

		a,
		.reject-btn {
			color: #fff;
			cursor: pointer;
			border-bottom: 1px solid #fff;
		}
	}
}

.tariff-date {
	text-align: right;
}

.tariff-name {
	display: flex;
	align-items: center;
	gap: 10px;

	i {
		display: flex;
		justify-content: center;
		align-items: center;
	}
}

.activated {
	background: #20bf55;
	color: white;
	font-size: 18px;
	font-weight: 500;
	line-height: 24px;
}

.profile {
	width: 100%;
	height: 100%;
	display: flex;
	flex-grow: 1;
	box-shadow: 0px 2px 10px 0px rgba(12, 11, 11, 0.05);

	/* gap: 90px; */

	.hidden {
		overflow: hidden;
	}

	nav {
		background-color: white;
		display: flex;
		flex-direction: column;
		width: 20%;
		box-sizing: border-box;
		max-height: calc(100vh - 140px);
		max-height: 100%;
		overflow: auto;

		a {
			text-decoration: none;
			display: flex;
			align-items: center;
			gap: 10px;
			box-sizing: border-box;
			padding: 25px 20px;
			color: #333;
			font-size: 18px;
			font-weight: 400;
			line-height: 26px;
			transition: all 0.3s;

			path {
				transition: all 0.3s;
			}

			&:hover {
				color: #4e41ab;

				path {
					stroke: #4e41ab;
				}
			}

			svg {
				width: 27px;
				height: 27px;
			}

			&.active {
				background: #8a7de7;
				color: white;

				svg {
					stroke: white !important;

					circle,
					path,
					polygon {
						stroke: white !important;
					}
				}
			}
		}
	}
}

.content {
	position: relative;
	width: 80%;
	background: #f9f9fb;
	padding: 30px 30px 60px 30px;
}

.tariff-content {
	position: relative;
	width: 80%;
	background: #f9f9fb;

	.banner-support {
		max-width: 2020px;
	}
}

.content-chat {
	width: 80%;
	background: #f9f9fb;

	/* height: calc(100vh - 320px); */
}

.important::after {
	content: '*';
	color: red;
}

.profile-overlay {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 44;
	box-sizing: border-box;
	padding: 50px;

	&.fixed {
		position: fixed;
	}

	&::before {
		content: '';
		position: absolute;
		width: 100%;
		height: 100%;
		left: 0;
		top: 0;
		background: rgba(138, 125, 231, 0.05);
		backdrop-filter: blur(4.5px);
	}
}

.profile-overlay-wrapper {
	width: 100%;
	position: relative;
	display: flex;
	justify-content: space-between;
}

.profile-overlay-content {
	max-width: 840px;
	width: 100%;
	position: relative;
	z-index: 3;
	border-radius: 13px;
	background: #333;
	box-sizing: border-box;
	padding: 30px;
	color: #fff;
	font-size: 26px;
	font-weight: 500;
	line-height: 34px;

	.profile-overlay-item {
		font-size: 16px;
	}
}

.profile-overlay-close {
	position: relative;
	z-index: 3;
	border-radius: 13px;
	box-sizing: border-box;
	color: #fff;
	font-size: 26px;
	font-weight: 500;
	line-height: 34px;
	text-decoration: none;

	button {
		color: #8a7de7;
		border: none;
		background: inherit;
		transform: scale(1);
	}
}

.profile-overlay-content a {
	color: #8a7de7;
	text-decoration: none !important;
	transition: all 0.3s;

	&:hover {
		color: #4e41ab;
	}
}

@media (max-width: 1060px) {
	.profile nav {
		width: 315px;
	}

	.content {
		position: relative;
		width: 100%;
		padding: 50px 30px 80px 50px;
		flex: 1;
	}

	.profile nav a {
		font-size: 18px;
		font-weight: 400;
		line-height: 29px;
	}

	.profile-overlay-content {
		font-size: 22px;
		line-height: 30px;
		padding: 25px;
		max-width: 100%;
	}

	.profile-overlay {
		padding: 30px;
	}

	.profile nav a {
		font-size: 16px;
		padding: 20px;
	}
}

@media (max-width: 768px) {
	.content-chat {
		width: 100%;

		/* height: calc(100vh - 286px); */
	}

	.profile nav {
		a {
			padding: 30px 0;
			width: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
			gap: 0;

			span {
				display: none;
			}
		}

		width: 67px;
	}

	.content {
		padding: 30px 20px 80px 20px;
	}

	.tariff-header {
		font-size: 16px;
		line-height: 22px;
	}

	.tariff-content {
		width: 100%;
	}

	.profile-overlay-content {
		font-size: 22px;
		line-height: 30px;
		padding: 25px;
	}

	.profile-overlay {
		padding: 20px;
	}
}

.navbar-mob {
	display: none;
}

@media (max-width: 480px) {
	.content-chat {
		height: auto;
	}

	.content {
		padding: 15px 20px 40px 20px;
	}

	.profile {
		flex-direction: column;
		position: relative;

		nav {
			width: 100%;

			a span {
				display: block;
			}
		}
	}

	.profile-overlay-close button {
		transform: scale(1);
	}

	.navbar-mob {
		display: flex;
		justify-content: space-between;
		align-items: center;
		height: 65px;
		width: 100%;
		box-shadow: 0px 2px 10px 0px rgba(12, 11, 11, 0.05);
		background: #8a7de7;
		color: #fff;
		box-sizing: border-box;
		padding: 0 20px;
		font-size: 16px;
		font-weight: 400;
		line-height: 22px;
	}

	.toggle-mob-menu {
		background: none;
		outline: none;
		border-radius: 3px;
		border: 1px solid #fff;
		width: 35px;
		height: 35px;
		display: flex;
		justify-content: center;
		align-items: center;
		position: relative;
		z-index: 66;

		&.active {
			background: #4e41ab;
			border-color: #4e41ab;
		}
	}

	.navbar-mob__title {
		display: flex;
		align-items: center;
		gap: 10px;

		svg {
			* {
				stroke: #fff;
			}

			width: 25px;
			height: auto;
		}
	}

	.profile nav {
		a {
			padding: 20px;
			display: flex;
			justify-content: flex-start;
			align-items: center;
			gap: 10px;
			font-size: 16px;
			font-weight: 400;
			line-height: 22px;

			svg {
				width: 25px;
				height: auto;
			}
		}

		width: 100%;
		width: 100%;
		left: 0;
		top: 0;
		z-index: -55;
		opacity: 0;
		transition: opacity 200ms ease-in;
		padding-top: 50px;
		padding-bottom: 50px;
		height: 100vh;
		overflow-y: auto;
		position: absolute;

		&.show {
			z-index: 55;
			opacity: 1;
			position: relative;
			top: -65px;
		}

		&::-webkit-scrollbar {
			width: 3px;
		}
	}

	.tariff-header {
		font-size: 12px;
		line-height: 16px;
	}

	.tariff-name {
		gap: 5px;

		svg {
			width: 16px;
			height: auto;
		}
	}

	.tariff-header {
		height: 46px;
		flex: 0 0 46px;
	}

	.profile nav a.active {
		background: #fff;
		color: #333;

		svg {
			stroke: #333 !important;

			circle,
			path,
			polygon {
				stroke: #333 !important;
			}
		}
	}

	.profile-overlay-content {
		font-size: 14px;
		line-height: 21px;
		padding: 15px;
	}

	.tariff-header {
		padding: 0 10px;
		font-size: 10px;
		line-height: 14px;
	}
}
