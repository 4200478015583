.header-svg {
	border-radius: 5px;
	cursor: pointer;
	transition: 0.5ms;

	&:hover {
		background-color: #8a7de7;
	}
}

.menu {
	position: relative;
	z-index: 1;
}

.header-icon {
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 5px;
	border: 1px solid #8a7de7;
	width: 38px;
	height: 38px;
	transition: all 0.3s;
	cursor: pointer;

	path,
	ellipse,
	circle {
		transition: all 0.3s;
	}

	&:hover {
		background: #4e41ab;

		path,
		ellipse,
		circle {
			stroke: #fff;
		}
	}
}

@media (max-width: 480px) {
	.header-icon {
		width: 35px;
		height: 35px;

		svg {
			width: 20px;
			height: 20px;
		}
	}
	.selected-result {
		svg {
			width: 18px !important;
			height: 18px !important;
			path {
				stroke-width: 1.2px;
			}
		}
	}
}
